function Container({
  children,
  className = '',
  maxWidth = 'md',
  noPadding = false,
}: {
  children: React.ReactNode;
  className?: string;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'max';
  noPadding?: boolean;
}) {
  const getMaxWidth = () => {
    if (maxWidth === 'max') return 'max-w-[2600px]';
    if (maxWidth === 'xl') return 'max-w-7xl';
    if (maxWidth === 'lg') return 'max-w-5xl';
    if (maxWidth === 'md') return 'max-w-4xl';
    if (maxWidth === 'sm') return 'max-w-2xl';
    return 'max-w-2xl';
  };
  return (
    <div className={`mx-auto ${getMaxWidth()} ${!noPadding && 'my-16 px-6'} ${className}`}>
      {children}
    </div>
  );
}

export default Container;
