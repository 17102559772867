import { datadogRum } from '@datadog/browser-rum';
import getEnvFromUrl from './getEnvFromUrl';

const initDataDog = () => {
  if (typeof window !== 'undefined') {
    console.log(`Datadog initialized with env: ${getEnvFromUrl(window.location.origin || '')}`);
    datadogRum.init({
      applicationId: '00cf823b-eaf9-479e-bb21-35afd7b2810b',
      clientToken: 'pubc269914b952bbdcfa69cd940ba2c7ea6',
      site: 'datadoghq.eu',
      service: 'mollerbil-cms',
      env: getEnvFromUrl(window.location.origin),
      version: process.env.NEXT_PUBLIC_BUILD_TAG_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
};

export default initDataDog;
