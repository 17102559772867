declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export const pageview = (url: string, title: string) => {
  window.dataLayer.push({
    event: 'pv',
    screenTitle: title,
    screenUrl: url,
  });
};

export const formSubmit = ({
  title,
  isSuccess,
  data = undefined,
}: {
  title: string;
  isSuccess: boolean;
  data: { [key: string]: string } | undefined;
}) => {
  let inputData;
  if (data) {
    if (data.carModel) {
      inputData = `dealership: ${data.dealership} - carModel: ${data.carModel}`;
    } else {
      inputData = `dealership: ${data.dealership}`;
    }
  }

  window.dataLayer?.push({
    event: 'formAction',
    eventCategory: 'forms',
    eventAction: isSuccess ? 'submitSuccess' : 'submitError',
    eventName: title,
    ...(inputData && { eventErrordata: inputData }),
  });
};
