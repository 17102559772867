/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Link from 'next/link';
import { Component } from 'react';
import Container from './Container';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container
          noPadding
          className="flex h-screen flex-col items-center justify-center gap-8 px-6 py-60 md:gap-14"
        >
          <div className="flex flex-col gap-4">
            <h1 className="text-center text-5xl font-semibold text-slate-700 md:text-6xl">
              Oops...
            </h1>
            <p className="max-w-prose text-center text-base font-medium text-gray-500 md:text-lg">
              En feil har oppstått!
            </p>
          </div>

          <Link
            href="/"
            className="ease bg-secondary hover:bg-primary active:bg-primary px-6 py-2 text-base font-medium tracking-wide text-white shadow-lg shadow-blue-500/50 duration-200 active:shadow-inner md:text-lg"
          >
            Tilbake til forsiden
          </Link>
        </Container>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
