const getEnvFromUrl = (url: string) => {
  switch (url) {
    case 'https://mollerbil.no':
      return 'prod';

    case 'https://stage.mollerbil.no':
      return 'stage';

    case 'https://dev.mollerbil.no':
      return 'dev';

    case 'https://test.mollerbil.no':
      return 'test';

    default:
      return undefined;
  }
};

export default getEnvFromUrl;
