import { create } from 'zustand';

interface HeaderVisibility {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
}

const useHeaderVisible = create<HeaderVisibility>((set) => ({
  isVisible: true,
  setIsVisible: (visibility) => set(() => ({ isVisible: visibility })),
}));

export default useHeaderVisible;
